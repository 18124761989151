<template>
  <section>
    <b-card>
      <div class="d-flex align-items-center">
        <span class="text-primary">Aggiungi auto da Eurotax </span>
        <b-breadcrumb class="ml-2">
          <b-breadcrumb-item>
            <feather-icon
              icon="HomeIcon"
              scale="1.25"
              shift-v="1.25"
            ></feather-icon>
          </b-breadcrumb-item>
          <b-breadcrumb-item active> Aggiungi da Eurotax</b-breadcrumb-item>
        </b-breadcrumb>
      </div>

      <div class="container w-lg-50 py-5 d-flex flex-column">
        <div>
          <p class="text-primary h4 text-center">Abbonamento EuroTax</p>
          <p class="text-center">
            <small
              >Questa sezione è disponibile solo per chi ha un abbonamento
              Eurotax. <br />
              Hai un abbonamento attivo? Inserisci le tue credenziali.</small
            >
          </p>
        </div>

        <img
          class="d-block mx-auto my-4"
          src="@/assets/images/auto-eurotax.svg"
        />

        <b-form-group label="Indirizzo e-mail">
          <b-form-input
            placeholder="Inserisci la tua e-mail per accedere a EuroTax"
          />
        </b-form-group>

        <b-form-group label="Password">
          <b-form-input
            placeholder="Inserisci la tua password per accedere a EuroTax"
          />
        </b-form-group>

        <b-button
          class="mt-2"
          block
          variant="primary"
          @click="$router.push('/car_fleet/eurotax/add_car')"
          >Accedi</b-button
        >
      </div>
    </b-card>
  </section>
</template>

<script>
import {
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BCardTitle,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BCardTitle,
    BFormGroup,
    BFormInput,
  },
};
</script>

<style></style>
